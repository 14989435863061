import React from 'react'

import useWidth from 'src/hooks/window/useWidth'

import SaibaMais from 'src/components/SaibaMais'
import GridCarousel from './_GridCarousel'

import { WIDTH_MD } from 'src/styles/breakpoints'
import * as S from './style'

const DiversasSolucoes = () => {
  const windowWidth = useWidth(300)

  return (
    <S.DiversasSolucoesSection>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-56 lh-xl-62 font-citrina fw-500 text-grayscale--500 mb-4 mb-xl-5'>Diversas soluções de Seguros na palma da mão</h2>
            {windowWidth < WIDTH_MD ? <CardsContainerMobile /> : <GridCarousel />}
          </div>
        </div>
      </div>
    </S.DiversasSolucoesSection>
  )
}

const CardsContainerMobile = () => {
  return (
    <div className='mobile-cards-container'>
      <S.PerdeuACarteira role='img' aria-label='cartões inter'>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Perdeu a carteira? Com Cartão Mais Protegido, tenha coberturas para perda e roubo do seu cartão além de sorteios de R$5 mil todo mês.</p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6'
        />
      </S.PerdeuACarteira>
      <S.CuidarDoSeuPresente>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Cuidar do seu presente e do futuro da sua família ficou mais simples. Com Seguro de Vida do Inter, indenizações de até R$ 150 mil, assistência hospitalar e muito mais.</p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6'
        />
      </S.CuidarDoSeuPresente>
      <S.LarDoceLar role='img' aria-label='mulher falando ao telefone'>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white'>Lar doce lar com ainda mais proteção. Seu Seguro Residencial contra roubos, incêndios e muito mais está aqui. </p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6' color='#FFFF'
        />
      </S.LarDoceLar>
      <S.VamosEvitarPrejuizos role='img' aria-label='homem e mulher lado a lado. A mulher segura em uma das mãos um celular e na outra o cartão inter'>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Vamos evitar prejuízos? Proteja ainda mais suas transações bancárias em casos de celular roubado e até transferências sob coação com Seguro Pix.</p>
        <SaibaMais
          dataLayer={{
          section: 'dobra_2',
          element_name: 'Saiba Mais',
          section_name: 'Diversas soluções de Seguros na palma da mão',
          redirect_url: 'https://inter.co/pra-voce/seguros/seguro-pix/',
          element_action: 'click button',
          }}
          url='https://inter.co/pra-voce/seguros/seguro-pix/'
        />
      </S.VamosEvitarPrejuizos>
      <S.ViajarEmPaz role='img' aria-label='cartões inter'>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Viajar em paz está mais simples com o Inter. Com Seguro Viagem, curta os dias de descanso sem preocupações com assistência 24h. </p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6'
        />
      </S.ViajarEmPaz>
      <S.SeFaltarGrana>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Se faltar grana, pagamos sua fatura do cartão Inter. Imprevistos acontecem, mas com Proteção Financeira fica mais fácil passar por eles.</p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6'
        />
      </S.SeFaltarGrana>
      <S.SuaVidaMaisTranquila role='img' aria-label='mulher entrando em um carro com um celular na mão'>
        <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white'>Sua vida mais tranquila no trânsito só aqui, com Auto Danos a Terceiros.</p>
        <SaibaMais
          dataLayer={{
            section: 'dobra_2',
            element_name: 'Saiba Mais',
            section_name: 'Diversas soluções de Seguros na palma da mão',
            redirect_url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
            element_action: 'click button',
          }}
          url='https://inter-co.onelink.me/Qyu7/l19up1w6'
        />
      </S.SuaVidaMaisTranquila>
    </div>
  )
}

export default DiversasSolucoes
