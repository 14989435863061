import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import SaibaMais from 'src/components/SaibaMais'

import { webpImages, IWebpImages } from '../../assets/images/_webp-images'

import { ImgWebp } from 'src/styles/imgWebp'
import * as S from './style'

interface ICard {
  text: string;
  url: string;
}

const CuidarDaSaude = () => {
  const {
    bannerCuidarBemDaSaude,
  }: IWebpImages = webpImages

  const card: ICard[] = [
    {
      text: 'Consulta on-line de medicina, psicologia e até nutrição no Super App? Em até 7 minutos, só com Doutor Inter',
      url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
    },
    {
      text: 'Seu plano odontológico presente em todo o país com urgência 24h após contratação e até 10 dependentes está aqui',
      url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
    },
    {
      text: 'Pra sua amizade pet viver ainda mais e melhor existe o Plano Pet. Clientes Inter têm desconto especial, viu?',
      url: 'https://inter-co.onelink.me/Qyu7/l19up1w6',
    },
  ]

  return (
    <S.CuidarDaSaudeSection className=''>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 order-md-2 mb-4 mb-md-0 text-xl-right'>
            <ImgWebp src={bannerCuidarBemDaSaude.xl} breakPointsSize={{ sm: '100%', md: '328px', lg: '372px', xl: '516px' }} alt='médica mostrando prontuário a mãe e filha' />
          </div>
          <div className='col-12 col-md-6 order-md-1'>
            <h2 className='fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 font-citrina fw-500 font-citrina text-grayscale--500'>Cuidar da saúde <span className='d-xl-block'>também é Inter</span></h2>
            <p className='fs-18 lh-22 text-grayscale--400'>Com o mesmo Super App, dá para cuidar de você, do seu sorriso e daqueles que você mais ama - inclusive seu pet.</p>
          </div>
          <div className='col-12 order-md-3'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1, partialVisibilityGutter: 250 }}
              lg={{ items: 2, partialVisibilityGutter: 40 }}
              xl={{ items: 2, partialVisibilityGutter: 100 }}
            >
              {card.map((item: ICard, index: number) => (
                <div key={index} className='mx-md-2'>
                  <S.Card>
                    <p className='fs-20 lh-24 fs-xl-24 lh-xl-28 font-citrina fw-500 text-grayscale--500'>{item.text}</p>
                    <SaibaMais
                      url={item.url}
                      dataLayer={{
                        section: 'dobra_3',
                        element_name: 'Conheça nossos produtos',
                        section_name: 'Seguros Inter: proteção pra tudo que importa',
                        redirect_url: item.url,
                        element_action: 'click button',
                      }}
                    />
                  </S.Card>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.CuidarDaSaudeSection>
  )
}

export default CuidarDaSaude
